import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

import { MessageService } from "../../shared/services/message.service";
import { SharedService } from "../../shared/services/shared.service";

@Component({
  selector: 'app-external-survey-form',
  templateUrl: './external-survey-form.component.html',
  styleUrls: ["../external-form.component.scss"],
})

export class ExternalSurveyFormComponent implements OnInit {
  surveyForm: FormGroup;
  clientCode: string;
  client: { firstName: string, lastName: string, email: string };
  isInvalidClient: boolean;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private message: MessageService,
              private translate: TranslateService,
              public sharedService: SharedService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.clientCode = params['userCode'];

      if (this.clientCode) {
        this.getClientInfo(this.clientCode);
      }
    });

    this.surveyForm = this.fb.group({
      optionOne: [null, [Validators.required]],
      optionTwo: [null, [Validators.required]],
      answerText: "",
      answers: [],
    });
  }

  getClientInfo(clientCode: string): void {
    this.sharedService.getSurveyClientInfo(clientCode).subscribe(
      (res) => {
        this.client = res.results;
      },
      (err) => {
        if (err.error.errorCode === 4) {
          this.isInvalidClient = true;
        }
      });
  }

  submitSurvey(): void {
    let data = {
      answers: []
    };

    data.answers.push(
      {
        questionId: 'SURVEY_QUESTION_1',
        rank: this.f.optionOne.value
      },
      {
        questionId: 'SURVEY_QUESTION_2',
        rank: this.f.optionTwo.value
      },
      {
        questionId: 'SURVEY_QUESTION_3',
        answerText: this.f.answerText.value
      });

    this.sharedService.submitExternalSurvey(this.clientCode, data).subscribe(
      () => {
        const translatedMessage = this.translate.instant('MSG.SUCCESSFULLY_SURVEY_SUBMITTED');

        this.message.success(translatedMessage);
        this.surveyForm.reset();
      },
      (err) => {
        this.message.error(err);
      }
    );
  }

  goToLogInPage(): void {
    this.router.navigate(["/auth"]);
  }

  get f(): any {
    return this.surveyForm.controls;
  }
}
