<div class="external-form external-form--survey">
  <header class="header">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center flex-column flex-sm-row">
        <a href="">
          <div class="header__logo"></div>
        </a>

        <button class="header__btn mt-3 mt-sm-0" (click)="goToLogInPage()">
          {{ 'HOME.MY_SUPERHUMAN' | translate }}
        </button>
      </div>
    </div>
  </header>

  <div class="page page-center" *ngIf="!isInvalidClient">
    <div class="text-white text-center">
      <div class="external-form__title">{{ 'TEXT.SURVEY_INTRO' | translate }}</div>

      <div class="fs-3">
        <span class="fw-bold">{{ "LBL.NAME" | translate }}:</span>
        {{ client?.firstName }} {{ client?.lastName }}
      </div>
      <div class="fs-3">
        <span class="fw-bold">{{ "LBL.EMAIL" | translate }}:</span>
        {{ client?.email }}
      </div>

      <div class="fst-italic fw-bold my-3">
        {{ 'TEXT.DIET_LOG_INFO' | translate }}
      </div>
    </div>
    <div class="container container-tight">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="surveyForm" novalidate (ngSubmit)="submitSurvey()">
            <div class="row">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">{{ "LBL.SURVEY_QUESTION_ONE" | translate }} *</label>
                  <div class="form-selectgroup">
                    <label class="form-selectgroup-item"
                           *ngFor="let option of [1,2,3,4,5]">
                      <input type="radio"
                             [value]="option"
                             class="form-selectgroup-input"
                             formControlName="optionOne"
                             [checked]="option === f.optionOne.value">
                      <span class="form-selectgroup-label">{{ option | translate }}</span>
                    </label>
                  </div>
                  <label class="form-label text-muted mt-1">{{ "LBL.SURVEY_QUESTION_INSTRUCTION" | translate }}</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">{{ "LBL.SURVEY_QUESTION_TWO" | translate }} *</label>
                  <div class="form-selectgroup">
                    <label class="form-selectgroup-item"
                           *ngFor="let option of [1,2,3,4,5]">
                      <input type="radio"
                             [value]="option"
                             class="form-selectgroup-input"
                             formControlName="optionTwo"
                             [checked]="option === f.optionTwo.value">
                      <span class="form-selectgroup-label">{{ option | translate }}</span>
                    </label>
                  </div>
                  <label class="form-label text-muted mt-1">{{ "LBL.SURVEY_QUESTION_INSTRUCTION" | translate }}</label>
                </div>
              </div>
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="form-label">{{ "LBL.SURVEY_QUESTION_THREE" | translate }}</label>
                  <textarea type="text"
                            class="form-control"
                            name="answerText"
                            rows="4"
                            formControlName="answerText"></textarea>
                </div>
              </div>
              <div class="col-md-12">
                <button class="btn btn-primary w-100 mt-3" [disabled]="surveyForm.invalid">
                  {{ "BTN.SUBMIT" | translate }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <div class="page page-center">
    <div class="container container-tight py-4">
      <app-not-found *ngIf="isInvalidClient"></app-not-found>
    </div>
  </div>
</div>
