<header class="navbar navbar-expand-md d-print-none">
  <div class="container-xl">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-menu"
            aria-controls="navbar-menu" aria-expanded="false" aria-label="Toggle navigation"
            (click)="isMobileMenuOpen = !isMobileMenuOpen">
      <span class="navbar-toggler-icon"></span>
    </button>
    <h1 class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
      <div class="cursor-pointer" (click)="goToHome()">
        <img src="./assets/img/logo-dark-text.png" height="40" alt="superhuman">
      </div>
    </h1>

    <h2 class="mb-0 d-none d-lg-block text-danger" *ngIf="isDevelopmentMode">DEVELOPMENT MODE</h2>

    <div class="navbar-nav">
      <div class="nav-item d-none d-md-flex me-3" *ngIf="isAdminClientView()">
        <button class="btn" [routerLink]="['/user/list']">
          <i class="ti ti-home text-warning icon"></i>
          {{ "BTN.ADMIN_DASHBOARD" | translate }}
        </button>
      </div>
      <div class="nav-item dropdown">
        <div class="nav-link d-flex lh-1 text-reset p-0 show cursor-pointer" data-bs-toggle="dropdown"
             aria-label="Open user menu" aria-expanded="true" (click)="onToggleMenu('profile')">
          <div class="ps-2">
            <ng-container *ngIf="isAdminClientView()">
              <div class="small text-muted">{{ "TEXT.MANAGING_CLIENT" | translate }}</div>
              <div class="mt-1">{{ selectedUser?.firstName }} {{ selectedUser?.lastName }}</div>
            </ng-container>
            <ng-container *ngIf="isAdminView() || isClientView()">
              <div class="small text-muted">{{ "TEXT.MY_PROFILE" | translate }}</div>
              <div class="mt-1">{{ user.firstName }} {{ user.lastName }}</div>
            </ng-container>
          </div>
        </div>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-arrow" data-bs-popper="static"
             *ngIf="isAdminView() || isClientView()"
             [class.show]="selectedLink === 'profile'">
          <a class="dropdown-item" [routerLink]="['/user/profile']">
            <i class="ti ti-user me-2"></i> {{ "TEXT.PROFILE" | translate }}
          </a>
          <a class="dropdown-item" [routerLink]="['/user/change-password']">
            <i class="ti ti-password me-2"></i> {{ "TEXT.CHANGE_PASSWORD" | translate }}
          </a>
          <div class="dropdown-divider"></div>
          <a href class="dropdown-item" (click)="onSignout()">
            <i class="ti ti-logout me-2"></i> {{ "TEXT.SIGN_OUT" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
<header class="navbar-expand-md">
  <div class="collapse navbar-collapse" id="navbar-menu" [class.show]="isMobileMenuOpen">
    <div class="navbar">
      <div class="container-xl">
        <div class="row flex-fill align-items-center">
          <div class="col d-block d-md-flex align-items-center">
            <ul class="navbar-nav">
              <li class="nav-item" *ngIf="isAdminView()"
                  [class.active]="router.url.includes('user/list')">
                <a class="nav-link align-items-center d-inline-flex"
                   [routerLink]="['/user/list']">
                  <div class="nav-link-icon h-auto d-md-none d-lg-inline-block fs-2">
                    <i class="ti ti-list"></i>
                  </div>
                  <div class="nav-link-title">{{ "TEXT.CLIENT_LIST" | translate }}</div>
                </a>
              </li>
              <li class="nav-item d-md-none" *ngIf="isAdminClientView()"
                  [class.active]="router.url.includes('user/list')">
                <a class="nav-link align-items-center d-inline-flex"
                   [routerLink]="['/user/list']">
                  <div class="nav-link-icon h-auto d-md-none d-lg-inline-block fs-2">
                    <i class="ti ti-list"></i>
                  </div>
                  <div class="nav-link-title">{{ "TEXT.CLIENT_LIST" | translate }}</div>
                </a>
              </li>
              <ng-container *ngIf="isAdminClientView() || isClientView()">
                <li class="nav-item" [class.active]="router.url.includes('client/dashboard')"
                    (click)="isMobileMenuOpen = false">
                  <a class="nav-link align-items-center d-inline-flex"
                     [routerLink]="['/client/dashboard']">
                    <div class="nav-link-icon h-auto d-md-none d-lg-inline-block fs-2">
                      <i class="ti ti-home"></i>
                    </div>
                    <div class="nav-link-title">{{ "TEXT.DASHBOARD" | translate }}</div>
                  </a>
                </li>
                <li class="nav-item" [class.active]="router.url.includes('client/result')"
                    (click)="isMobileMenuOpen = false">
                  <a class="nav-link align-items-center d-inline-flex"
                     [routerLink]="['/client/result']">
                    <div class="nav-link-icon h-auto d-md-none d-lg-inline-block fs-2">
                      <i class="ti ti-star"></i>
                    </div>
                    <div class="nav-link-title">{{ "TEXT.RESULTS" | translate }}</div>
                  </a>
                </li>
                <li class="nav-item" [class.active]="router.url.includes('client/superhuman')"
                    (click)="isMobileMenuOpen = false">
                  <a class="nav-link align-items-center d-inline-flex"
                     [routerLink]="['/client/superhuman']">
                    <div class="nav-link-icon h-auto d-md-none d-lg-inline-block fs-2">
                      <i class="ti ti-rocket"></i>
                    </div>
                    <div class="nav-link-title">{{ "TEXT.MY_SUPERHUMAN_PROGRAM" | translate }}</div>
                  </a>
                </li>
                <li class="nav-item" *ngIf="isAdminClientView()"
                    [class.active]="router.url.includes('user/client/details')">
                  <a class="nav-link align-items-center d-inline-flex"
                     [routerLink]="['/user/client/details']">
                    <div class="nav-link-icon h-auto d-md-none d-lg-inline-block fs-2">
                      <i class="ti ti-layout-2"></i>
                    </div>
                    <div class="nav-link-title">{{ "TEXT.PROFILE" | translate }}</div>
                  </a>
                </li>
                <li class="nav-item" *ngIf="isAdminClientView()"
                    [class.active]="router.url.includes('client/survey')">
                  <a class="nav-link align-items-center d-inline-flex"
                     [routerLink]="['/client/survey']">
                    <div class="nav-link-icon h-auto d-md-none d-lg-inline-block fs-2">
                      <i class="ti ti-file-analytics"></i>
                    </div>
                    <div class="nav-link-title">{{ "TEXT.SURVEYS" | translate }}</div>
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
